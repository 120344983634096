import React from "react";
import { useState } from "react";
import { DataPoint } from "./types";
import { useApi, configApiRef } from '@backstage/core-plugin-api';

function timestampsToISO(unixDate: number) {
    let date = new Date(unixDate * 1000);
    return date.toISOString();
}


export const useFetchPrometheusData = (query: string) => {
    
    const config = useApi(configApiRef);
    const [data, setData] = useState<DataPoint[] | []>([]);
    const [error, setError] = useState<string | null>(null);
    
    const backendUrl = config.getString('backend.baseUrl');
    // Url for prometheus proxy
    const baseUrl = backendUrl + '/api/proxy/prometheus/api/v1/'
    const url = (baseUrl + "query_range?query=" + query);
    const processResponseData = (data: [number, string][]): DataPoint[] => {
        if (Array.isArray(data) && data.length > 0) {
            const sortedData = data.sort((a, b) => a[0] - b[0]);
            return sortedData.map(subArray => [
                timestampsToISO(subArray[0]),
                Number(subArray[1]),
            ]);
        } else {
            return [];
        }
    };

    const fetchData = async () => {
        try {
            const response = await fetch(url);
            if (!response.ok) {
                switch (response.status) {
                    case 404:
                        console.error('Resource not found:', await response.json());
                        setError('Resource not found.');
                        setData([]);
                        return;

                    case 500:
                        console.error('Internal server error:', await response.json());
                        setError('Internal server error. Please try again later.');
                        setData([]);
                        return;

                    default:
                        console.error('Unexpected error:', await response.json());
                        setError('An unexpected error occurred.');
                        setData([]);
                        return;
                }
            }

            const data = await response.json();

            if (data.data && data.data.result) {
                // result array looks like this [[dataContainer1],[dataContainer2]]. Flatten to get all values if container starts in requested Interval.
                const flattenedValues = data.data.result.flatMap((item: { values: number[] }) => item.values);
                const convertedData = processResponseData(flattenedValues);
                setError(null);
                setData(convertedData);
            } else {
                console.error('Unexpected response structure:', data);
                setError('Empty response.');
                setData([]);
            }
        } catch (error) {
            console.error('Error fetching data:', (error as Error).message);
            setError('Error fetching data. Please check your network or the endpoint.');
            setData([]);
        }
    };


    React.useEffect(() => {
        fetchData();
    }, [url]);
    return [data, error] as const;
}

export default useFetchPrometheusData