// the component is responsible for displaying the data as table

import React from "react";
import { DataPoint } from "./types";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { useEntity } from "@backstage/plugin-catalog-react";

interface DataListProps {
    data: DataPoint[] | [];
    error: string | null;
}

const PrometheusDataList: React.FC<DataListProps> = ({
    data,
    error,
}) => {
    if (error)
        return <div>error occurred.unable to load data</div>;
    if (!data) return null;
    const {entity} = useEntity();
    const rowNames = ["Time", entity?.metadata?.annotations?.["prometheus/title"]]
    return (
        <Table>
            <TableHead>
                <TableRow key={"row0"}>
                    {rowNames.map((key) => (
                        <TableCell key={"row0" + key}>{key}</TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map((row: DataPoint, index) => (
                    <TableRow key={index}>
                        <TableCell>{row[0]}</TableCell>
                        <TableCell>{row[1]}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

export default PrometheusDataList;