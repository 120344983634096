import React from "react";
import {Card, CardContent,Typography} from '@material-ui/core'
import { useEntity } from "@backstage/plugin-catalog-react";


export function GrafanaCard(){
    const { entity } = useEntity();
    const name = entity.metadata.name;
    const description = entity.metadata.description;
    const dashboardLink = entity?.metadata?.annotations?.["grafana/dashboard-graph"] ?? '';
    
    return (
    <Card>
        <CardContent>
            <Typography variant="h5">
                Application insights
            </Typography>
            <Typography color="secondary" gutterBottom>
                {name} - {description}                
            </Typography>
            <iframe src={dashboardLink} id="dashboard" width="100%" height="1000" style={{color: 'success.main' }}></iframe>
        </CardContent>
    </Card>
    )
}