import React from "react";
import { Card, CardContent, Typography } from '@material-ui/core'
import { useEntity } from "@backstage/plugin-catalog-react";
import useFetchPrometheusData from "./prometheus-data";
import PrometheusGraph from "./prometheus-graph";
import { useState } from "react";
import { useEffect } from "react";

const generateQuery = (now: number, timerange: number, entity: any) => {
    const start = new Date(now - timerange).toISOString();
    const end = new Date(now).toISOString();
    const annotations = entity?.metadata?.annotations || {};
    const annotationQuery = annotations["prometheus/query"] ?? '';
    return `${encodeURIComponent(annotationQuery)}&start=${start}&end=${end}&step=1s`;
};

export const PrometheusCard = () => {
    const { entity } = useEntity();
    const timerangeMS = 2 * 60 * 1000; // 2 minutes
    const [query, setQuery] = useState<string>(() => generateQuery(Date.now(), timerangeMS, entity));
    const [data, error] = useFetchPrometheusData(query);

    useEffect(() => {
        const interval = setInterval(() => {
            const now = Date.now();
            setQuery(generateQuery(now, timerangeMS, entity));
        }, 1000);

        return () => clearInterval(interval);
    }, [entity]);

    return (
        <Card>
            <CardContent>
                <Typography variant="h5">
                    {entity?.metadata?.annotations?.["prometheus/title"]}
                </Typography>
                <PrometheusGraph data={data} error={error} />
            </CardContent>
        </Card>
    )
}