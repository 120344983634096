import React from "react";
import PrometheusDataList from "./prometheus-list";
import { useFetchPrometheusData } from "./prometheus-data";
import { useState } from "react";
import { Button, Card, CardContent, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from "@material-ui/core";
import PrometheusGraph from "./prometheus-graph";
import { useEntity } from "@backstage/plugin-catalog-react";
import { useId} from "react";

const TIME_OPTIONS = [
    { value: 10 * 60 * 1000, label: '10 Minutes' },
    { value: 30 * 60 * 1000, label: '30 Minutes' },
    { value: 60 * 60 * 1000, label: '1 Hour' },
    { value: 24 * 60 * 60 * 1000, label: '1 Day' },
    { value: 2 * 24 * 60 * 60 * 1000, label: '2 Days' },
];

const PROMETHEUS_REQUESTED_POINTS = 100;

export const PrometheusContainer = () => {
    const { entity } = useEntity();

    const [query, setQuery] = useState<string>('');
    const [timerange, setTimerange] = useState<number>(TIME_OPTIONS[0].value);
    const [data, error] = useFetchPrometheusData(query);
    const [isTableView, setIsTableView] = useState<boolean>(true);

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault(); // Prevent form submission
        const end = new Date().toISOString();
        const start = new Date(Date.now() - timerange).toISOString();
        const resolution = Math.ceil((timerange) / (PROMETHEUS_REQUESTED_POINTS * 1000))
        const annotationQuery = entity?.metadata?.annotations?.["prometheus/query"] ?? '';
        let newQuery = encodeURIComponent(annotationQuery) + `&start=${start}&end=${end}&step=${resolution}s`

        setQuery(newQuery);

    };
    return (
        <div className="grid-container">
            <div className="grid-item">
                <FormControl fullWidth>
                    <InputLabel id={useId()}>Select Time</InputLabel>
                    <Select
                        labelId={useId()}
                        id={useId()}
                        value={timerange}
                        label="Select Time"
                        onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                            setTimerange(event.target.value as number)
                        }}
                    >
                        {TIME_OPTIONS.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}

                    </Select>
                </FormControl>
            </div>

            <div className="grid-item">
                <Button variant="outlined" type="submit" onClick={handleSubmit}>
                    Fetch Data
                </Button>
            </div>

            <div className="grid-item">
                <Button onClick={() => { setIsTableView(prev => !prev) }}>
                    Switch to {isTableView ? 'Graph' : 'Table'}
                </Button>
            </div>
            <div className="grid-item grid-full" style={{ gridColumn: 'span 3' }}>
            {isTableView ? (
                <PrometheusDataList data={data} error={error} />
            ) : (
                <Grid container spacing={1} alignItems="stretch">
                        <Card>
                            <CardContent>
                                <Typography variant="h5">
                                    {entity?.metadata?.annotations?.["prometheus/title"]}
                                </Typography>
                                <PrometheusGraph data={data} error={error} />
                            </CardContent>
                        </Card>
                </Grid>
            )}
        </div>
        </div>
    );
}